import React from 'react'
import Typography from '@mui/material/Typography'
import Page from 'components/Page'

function NotFoundPage() {
  return (
    <Page title="Not Found">
      <Typography>You just hit a route that doesn't exist</Typography>
    </Page>
  )
}

export default NotFoundPage
